.page h1 {
  font-size: 32px;
  margin: 0 0 50px;
}
.page h2 {
  font-size: 26px;
  margin: 0 0 28px;
}
.page h3 {
  font-size: 30px;
  font-weight: normal;
  margin: 0 0 8px;
}
.page p {
  font-size: 14px;
  line-height: 1.6;
  margin: 0 0 28px;
}
.cols,
.bullets {
  margin-left: -15px;
  margin-right: -15px;
}
.cols:after,
.bullets:after {
  content: '';
  display: table;
  clear: both;
}
.download {
  margin-bottom: 90px;
}
.text {
  min-height: 1px;
  float: left;
  width: 41.66666666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.image {
  min-height: 1px;
  float: left;
  width: 58.333333333333336%;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.slide1 {
  display: inline-block;
  background-image: url("../../../styles/img/slide1.jpg");
  width: 633px;
  height: 364px;
}
.slide2 {
  display: inline-block;
  background-image: url("../../../styles/img/slide2.jpg");
  width: 633px;
  height: 364px;
}
.bullet {
  min-height: 1px;
  float: left;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.contacts {
  font-weight: bold;
  margin-top: 150px;
}
.phone {
  color: #1c73c4;
  font-size: 34px;
  margin-bottom: 15px;
}
.cta {
  font-size: 22px;
  margin-bottom: 20px;
}
.email {
  font-size: 26px;
  color: #306bb3;
}
.email:hover {
  text-decoration: underline;
}
.form {
  margin-top: 90px;
  width: 285px;
}
