.captcha {
  margin-left: -15px;
  margin-right: -15px;
}
.captcha:after {
  content: '';
  display: table;
  clear: both;
}
.captcha_input {
  min-height: 1px;
  float: left;
  width: 160px;
  margin-left: 15px;
  margin-right: 15px;
}
.captcha_img {
  min-height: 1px;
  float: left;
  width: 160px;
  margin-left: 15px;
  margin-right: 15px;
}
