.wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.wrapper:after {
  content: '';
  display: table;
  clear: both;
}
.form {
  min-height: 1px;
  float: left;
  width: 350px;
  margin-left: 15px;
  margin-right: 15px;
}
.input {
  margin-bottom: 20px;
}
.link {
  display: inline-block;
  vertical-align: middle;
  color: #306bb3;
  margin-left: 15px;
}
.link:hover {
  color: #1c73c4;
}
.resend {
  margin-bottom: 20px;
}
