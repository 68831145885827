.page
  h1
    font-size: 32px
    margin: 0 0 50px
  h2
    font-size: 26px
    margin: 0 0 28px
  h3
    font-size: 30px
    font-weight: normal
    margin: 0 0 8px
  p
    font-size: 14px
    line-height: 1.6
    margin: 0 0 28px

.cols,
.bullets
  container()

.download
  margin-bottom: 90px

.text
  spanFluid(5)

.image
  spanFluid(7)
  text-align: center

.slide1
  display: inline-block
  background-image: url(../../../styles/img/slide1.jpg)
  width: 633px
  height: 364px

.slide2
  display: inline-block
  background-image: url(../../../styles/img/slide2.jpg)
  width: 633px
  height: 364px

.bullet
  spanFluid(6)

.contacts
  font-weight: bold
  margin-top: 150px

.phone
  color: $dark-red
  font-size: 34px
  margin-bottom: 15px

.cta
  font-size: 22px
  margin-bottom: 20px

.email
  font-size: 26px
  color: $dark-blue

  &:hover
    text-decoration: underline

.form
  margin-top: 90px
  width: ( column-width * 3 ) + ( column-gutter * 2 * 3 )