.agree
  display: flex
  margin-bottom: 20px

.agree_text
  margin-left: 12px
  font-size: 12px
  line-height: 1.55
  color: $grey700
  display: block
  max-width: 460px

  a
    color: $dark-blue
    text-decoration: none

    &:hover
      text-decoration: underline

.errors
  color: $dark-red
  font-size: 13px
  line-height: 1.2
  margin-top: 3px

.error
  display: inline-block