.page
  h1
    font-size: 32px
    margin: 0 0 50px
  h2
    font-size: 22px
    margin: 0 0 16px
  h3
    font-size: 18px
    font-weight: normal
    margin: 0 0 8px
  p
    font-size: 14px
    line-height: 1.6
    margin: 0 0 20px

.download
  margin: 30px 0

.image
  text-align: center
  max-width: 100%
  height: auto

.slide1
  display: inline-block
  background-image: url(../../../styles/img/slide1.jpg)
  width: 633px
  height: 364px

.slide2
  display: inline-block
  background-image: url(../../../styles/img/slide2.jpg)
  width: 633px
  height: 364px

.contacts
  font-weight: bold
  margin-top: 40px

.phone
  color: $dark-red
  font-size: 26px
  margin-bottom: 10px

.cta
  font-size: 16px
  margin-bottom: 15px

.email
  font-size: 20px
  color: $dark-blue

  &:hover
    text-decoration: underline

.form
  margin-top: 40px
