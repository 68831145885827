.icon {
  display: inline-block;
  vertical-align: middle;
  width: 11px;
  fill: #717171;
  margin-right: 12px;
}
.items {
  position: relative;
  border-top: 0;
  margin-bottom: 19px;
  border-top: 1px solid #e0e0e0;
}
.item {
  display: block;
  width: 100%;
  height: 44px;
  line-height: 43px;
  padding-left: 38px;
  border: 1px solid #e0e0e0;
  border-top: 0;
  background: #fff;
}
.item_open,
.item.active {
  padding-left: 14px;
}
.menu {
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
}
.open {
  display: block;
}
.toggler {
  display: none;
}
.toggler:checked + .menu {
  display: block;
}
