.icon
  display: inline-block
  vertical-align: middle
  width: 11px
  fill: $grey700
  margin-right: 12px

.items
  position: relative
  border-top: 0
  margin-bottom: 19px
  border-top: 1px solid $grey200

.item
  display: block
  width: 100%
  height: 44px
  line-height: 43px
  padding-left: 38px
  border: 1px solid $grey200
  border-top: 0
  background: $white

  &_open, &.active
    padding-left: 14px

.menu
  display: none
  position: absolute
  width: 100%
  top: 100%
  left: 0
  
.open
  display: block

.toggler
  display: none
  &:checked + .menu
    display: block