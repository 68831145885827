.wrapper
  &:hover .pseudo
    stroke: $grey500

.label
  display: inline-block
  vertical-align: middle

.checkbox
  display: none

.pseudo
  position: relative
  display: block
  width: 14px
  height: 14px
  fill: $white
  stroke $grey700
  transition: all .1s ease-out

  .checkbox:checked + &
    fill: $black
