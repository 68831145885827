.param
  margin-bottom: 18px
  font-size: 13px
  line-height: 1.2
  cf()

$label-width = 150px;
.label
  color: $grey700
  width: $label-width
  float: left

.value
  font-weight: bold
  margin-left: $label-width
  word-wrap: break-word
