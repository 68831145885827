.wrapper {
  display: block;
  margin-bottom: 20px;
}
.input {
  display: block;
  border-radius: 3px;
  border: 1px solid #adadad;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  width: 100%;
  font-size: 14px;
  outline: 0;
  color: #333;
  transition: all 0.1s ease-out;
  font-family: inherit;
}
.input:focus {
  border-color: #717171;
}
.textarea {
  min-width: 100%;
  max-width: 100%;
  height: 60px;
  line-height: 1.3;
}
.label {
  display: block;
  color: #717171;
  font-size: 13px;
  margin-bottom: 10px;
}
.errors {
  color: #1c73c4;
  font-size: 13px;
  margin-top: 3px;
}
.error {
  display: inline-block;
  margin: 0 3px;
}
