.input {
  margin-bottom: 20px;
}
.params {
  margin: 23px 0;
}
.param {
  margin-bottom: 14px;
  font-size: 14px;
}
.param:after {
  content: '';
  display: table;
  clear: both;
}
.label {
  width: 30%;
  float: left;
  color: #717171;
}
.value {
  width: 70%;
  float: left;
  font-weight: bold;
}
