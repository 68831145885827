.wrapper:hover .pseudo {
  stroke: #adadad;
}
.label {
  display: inline-block;
  vertical-align: middle;
}
.checkbox {
  display: none;
}
.pseudo {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  fill: #fff;
  stroke: #717171;
  transition: all 0.1s ease-out;
}
.checkbox:checked + .pseudo {
  fill: #333;
}
