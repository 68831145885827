.param {
  margin-bottom: 18px;
  font-size: 13px;
  line-height: 1.2;
}
.param:after {
  content: '';
  display: table;
  clear: both;
}
.label {
  color: #717171;
  width: 150px;
  float: left;
}
.value {
  font-weight: bold;
  margin-left: 150px;
  word-wrap: break-word;
}
