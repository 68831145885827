.captcha:after {
  content: '';
  display: table;
  clear: both;
}
.captcha_input {
  margin-right: 150px;
}
.captcha_img {
  float: right;
  margin-top: 11px;
}
