.wrapper
  display: block
  margin-bottom: 22px

.input
  display: block
  border-radius: 3px
  border: 1px solid $grey500
  height 36px
  line-height: 34px
  padding: 0 10px
  width: 100%
  font-size: 16px
  outline: 0
  color: $black
  transition: all .1s ease-out
  font-family: inherit

  &:focus
    border-color: $grey700

.textarea
  min-width: 100%
  max-width: 100%
  height: 60px
  line-height: 1.3

.label
  display: block
  color: $grey700
  font-size: 14px
  margin-bottom: 10px

.errors
  color: $dark-red
  font-size: 13px
  margin-top: 3px

.error
  display: inline-block
  margin: 0 3px