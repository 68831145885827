.input {
  margin-bottom: 20px;
}
.links {
  margin-top: 12px;
  text-align: center;
}
.link {
  display: inline-block;
  vertical-align: middle;
  color: #306bb3;
  margin: 0 10px;
}
.link:hover {
  color: #1c73c4;
}
