.button
  text-align: center

.input
  margin-bottom: 20px

.order
  margin-top: 35px

.header
  font-size: 16px
  font-weight: bold
  margin-bottom: 32px

.param
  margin-bottom: 14px
  font-size: 14px
  cf()

.label
  width: 50%
  float: left
  color: $grey700

.value
  width: 50%
  float: left
  font-weight: bold

.address
  font-size: 14px
  font-weight: bold
  margin-top: 35px