.agree {
  display: flex;
  margin-bottom: 20px;
}
.agree_text {
  margin-left: 12px;
  font-size: 12px;
  line-height: 1.55;
  color: #717171;
  display: block;
  max-width: 460px;
}
.agree_text a {
  color: #306bb3;
  text-decoration: none;
}
.agree_text a:hover {
  text-decoration: underline;
}
.errors {
  color: #1c73c4;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 3px;
}
.error {
  display: inline-block;
}
