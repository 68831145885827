.input
  margin-bottom: 20px

.links
  margin-top: 12px
  text-align: center

.link
  display: inline-block
  vertical-align: middle
  color: $dark-blue
  margin: 0 10px

  &:hover
    color: $dark-red

.resend
  margin-bottom: 20px
