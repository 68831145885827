.wrapper
  container()

.form
  span(4)

.input
  margin-bottom: 20px

.link
  display: inline-block
  vertical-align: middle
  color: $dark-blue
  margin-left: 15px

  &:hover
    color: $dark-red
